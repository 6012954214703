import {ShopifyProduct} from '../../../../interfaces/shopify-product.interface';
import {variantsComponentView} from './variants_component.view';
import {InputEventUtils} from '../../../../utils/input_event_utils';
import {ShopifyService} from '../../../../api/services/shopify.service';
import {Metrics} from '../../../../metrics';
import {MATOMO_EVENT_NAMES} from '../../../../metric-events';
import {AppState} from '../../../../shared/app.state';
import {UrlUtils} from '../../../../api/urls';

export class VariantsComponent {
  private readonly variantsContainer: HTMLElement;
  private selectedValue: string;
  private selectedInput: HTMLInputElement;

  constructor(
    private option: any,
    private product: ShopifyProduct,
    private mainContainer: HTMLElement,
    private shopifyService: ShopifyService
  ) {
    if (!this.option.values.includes('Default Title')) {
      this.option = {...this.option, values: ['Please select', ...this.option.values]};
    }
    this.initComponent();
    this.variantsContainer = document.querySelector<HTMLElement>(`.option${this.option.position}`);
    this.selectedInput = this.variantsContainer.querySelector<HTMLInputElement>('input:checked');
    this.selectedValue = this.selectedInput?.value;
    this.shopifyService.updateProductVariant(`option${this.option.position}`, this.selectedValue);

    InputEventUtils.addSelectEvents(this.variantsContainer, this.handleClicks.bind(this));
  }

  private initComponent() {
    const variantsContainer = this.mainContainer.querySelector<HTMLElement>('.app-shopify-variants-container');
    const variantsWrapper = document.createElement('div');
    variantsContainer.append(variantsWrapper);
    variantsWrapper.outerHTML = variantsComponentView(this.option);

    if (this.product?.variants?.length) {
      this.selectVariant();
    }
  }

  private selectVariant() {
    const selectedInput = this.mainContainer.querySelector<HTMLElement>(`input[name='option${this.option.position}']`);
    if (selectedInput) {
      (selectedInput as HTMLInputElement).checked = true;
    }
  }

  private handleInputChange() {
    this.selectedInput = this.variantsContainer.querySelector<HTMLElement>('input:checked') as HTMLInputElement;
    const currentValue = this.selectedInput.value;
    Metrics.storeTheEvent(
      AppState.planogramName,
      'select',
      MATOMO_EVENT_NAMES.SELECT_ECOMMERCE_OVERLAY_PRODUCT_VARIANT(
        UrlUtils.slugify(this.option.name),
        UrlUtils.slugify(currentValue),
        this.product.identifier,
        UrlUtils.slugify(this.product.name)
      )
    );

    if (this.selectedValue !== currentValue) {
      this.selectedValue = currentValue;
      this.shopifyService.updateProductVariant(`option${this.option.position}`, this.selectedValue);
    }
  }

  private handleClicks(e) {
    // to prevent duplication of click events (click on input is fired after clicking on label)
    if (e.target.tagName === 'INPUT') {
      this.handleInputChange();
      return;
    } else {
      // to hide validation message when list is active
      this.selectedInput?.setCustomValidity('');
      this.selectedInput?.reportValidity();
    }

    if (this.option?.values.length <= 1) {
      this.variantsContainer.classList.add('disabled');
      return;
    }

    const allVariants = document.querySelectorAll('.app-shopify-product-variants');
    allVariants.forEach(variant => {
      if (variant !== this.variantsContainer) {
        variant.classList.remove('show-list');
      }
    });

    this.variantsContainer.classList.remove('disabled');
    this.variantsContainer.classList.toggle('show-list');
  }

  validate() {
    if (this.selectedInput?.value === 'Please select') {
      this.selectedInput?.setCustomValidity('Please select one of the variants');
    } else {
      this.selectedInput?.setCustomValidity('');
    }
    return this.selectedInput?.reportValidity();
  }
}
