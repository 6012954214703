import type { Texture, WebGLRenderer } from 'three';
import {
  ClampToEdgeWrapping,
  DataTexture,
  LinearFilter,
  RGBAFormat,
  UVMapping,
  UnsignedByteType,
  Vector2,
} from 'three';

import { assertTrue, debugCommand } from 'shared/utils/debug';

import { TILE_SIZE } from './parameters';

export type SlotIndex = number;

export default class PhysicalTextures {
  // TODO: use DataArrayTexture?
  readonly textures: Array<DataTexture> = []; // indices are texture ids
  private occupied: Array<boolean> = []; // indices are slot ids
  private _freeSlots: number;
  private uploadQueue: [Texture, (slot: number) => void][] = [];

  public readonly totalSlots: number;
  public get freeSlots(): number {
    return this._freeSlots;
  }

  constructor(
    readonly textureCount: number,
    readonly textureSize: number,
    private renderer: WebGLRenderer,
  ) {
    const side = this.textureTileSide();
    this.totalSlots = side * side * textureCount;
    this._freeSlots = this.totalSlots;
    for (let i = 0; i < this.totalSlots; i++) this.occupied.push(false);

    const data = new Uint8Array(4 * textureSize * textureSize).fill(0);
    for (let i = 0; i < textureCount; i++) {
      const texture = new DataTexture(
        data,
        textureSize,
        textureSize,
        RGBAFormat,
        UnsignedByteType,
        UVMapping,
        ClampToEdgeWrapping,
        ClampToEdgeWrapping,
        LinearFilter,
        LinearFilter,
      );
      texture.needsUpdate = true;
      this.textures.push(texture);
    }

    debugCommand('freeSlots', () => this.freeSlots);
  }

  storeTile(tileTexture: Texture): Promise<SlotIndex> {
    if (this._freeSlots === 0) throw new Error('Physical textures are full');
    this._freeSlots--;
    return new Promise(resolve => {
      // TODO: prevent storing the same texture in multiple slots?
      this.uploadQueue.push([tileTexture, resolve]);
    });
  }

  freeSlot(slot: SlotIndex) {
    assertTrue(this.occupied[slot], 'Freeing a free slot');
    this._freeSlots++;
    this.occupied[slot] = false;
  }

  update() {
    const FRAME_LIMIT = 2;
    const noWork = this.uploadQueue.length === 0;
    this.uploadQueue.splice(0, FRAME_LIMIT).forEach(([texture, resolve]) => {
      const slot = this.findFreeSlot();
      this.occupied[slot] = true;
      const physicalTexture = this.textures[this.tileSlotTexture(slot)];
      const position = this.tileSlotCoordinate(slot);
      this.renderer.copyTextureToTexture(position, texture, physicalTexture);
      resolve(slot);
    });
    return noWork;
  }

  private findFreeSlot(): SlotIndex {
    const free = this.occupied.findIndex(v => !v);
    if (free === -1) throw new Error('Physical textures are full');
    return free;
  }

  private textureTileSide() {
    return Math.floor(this.textureSize / TILE_SIZE);
  }

  tileSlotTexture(slot: number) {
    const side = this.textureTileSide();
    return Math.floor(slot / (side * side));
  }

  tileSlotCoordinate(slot: number) {
    const side = this.textureTileSide();
    const textureSlot = slot % (side * side);
    const slotPosition = new Vector2(textureSlot % side, Math.floor(textureSlot / side));
    return slotPosition.multiplyScalar(TILE_SIZE);
  }

  dispose() {
    this.textures.forEach(it => it.dispose());
    this.uploadQueue = [];
  }
}
