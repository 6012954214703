import {Vector2} from 'three';
import {ImageMetaData} from '../../interfaces/planogram.interface';
import {Action, isAnimateAction, isProductOverlayAction, isSingleImageAction} from 'shared/interfaces/planogram';
import {ActionType} from 'shared/interfaces/planogram';
import {CLICK_EVENT_TYPES, HEATMAP_EVENT_TYPES} from '../../shared/constants';
import {SphereItemType} from 'shared/interfaces/planogram';
import {ApiService} from '../api';
import {URLS} from '../urls';
import {debounce} from 'throttle-debounce';
import {SphereItem} from '../../sphere_item';
import {AppUtils} from '../../utils/app_utils';
import {CookiesManagement} from '../../cookies_management';

const itemTypeToClickType: Partial<Record<SphereItemType, CLICK_EVENT_TYPES>> = {
  [SphereItemType.Image]: CLICK_EVENT_TYPES.IMAGE,
  [SphereItemType.Product]: CLICK_EVENT_TYPES.PRODUCT_OVERLAY,
  [SphereItemType.Cluster]: CLICK_EVENT_TYPES.CLUSTER
};

function actionTypeToClickEvent(action: Action): CLICK_EVENT_TYPES {
  if (action.type === ActionType.AboutUs) return CLICK_EVENT_TYPES.ABOUT_US;
  if (action.type === ActionType.LegalNotice) return CLICK_EVENT_TYPES.COPYRIGHT;
  if (action.type === ActionType.ExternalLink) return CLICK_EVENT_TYPES.LINK;
  if (action.type === ActionType.PrivacyPolicy) return CLICK_EVENT_TYPES.PRIVACY;
  if (isAnimateAction(action)) return itemTypeToClickType[action.data.itemType] ?? CLICK_EVENT_TYPES.EMPTY;
  if (isSingleImageAction(action)) return CLICK_EVENT_TYPES.IMAGE;
  if (isProductOverlayAction(action)) return CLICK_EVENT_TYPES.PRODUCT_OVERLAY;
}

function makeItemUrl(item: SphereItem): string {
  let itemUrl: string = '';
  const action = item.action;

  if (isAnimateAction(action)) {
    switch (action.data.itemType) {
      case SphereItemType.Cluster:
        itemUrl = `&object_type=cluster&object_id=${AppUtils.extractClusterName(action.data.clusterLink)}`;
        break;
      case SphereItemType.Image:
        itemUrl = `&object_type=image&object_id=${action?.data?.imageId}`;
        break;
      case SphereItemType.Product:
        itemUrl = `&object_type=product&object_id=${action?.data?.productIdentifier}`;
        break;
    }
  }

  if (isProductOverlayAction(action)) {
    itemUrl = `&object_type=product&object_id=${item.data.product.identifier || action.data.productIdentifier}`;
  }

  if (isSingleImageAction(action)) {
    itemUrl = `&object_type=image&object_id=${
      (item.data as ImageMetaData)?.picture?.id || (item.data as ImageMetaData)?.id
    }`;
  }

  return `&click_event_type=${actionTypeToClickEvent(item.action)}${itemUrl}`;
}

export class HeatMapService extends ApiService {
  MATOMO_USER_ID_REGEX = /_pk_id\.[^\..]+\.[^=]+=([^\.]+)/;
  MATOMO_SESSION_ID_REGEX = /_pk_ses\.[^\..]+\.[^=]+=([^\. ;]+)/;

  matomoUserId: string;
  matomoSessionId: string;

  constructor(
    private planogramVersionId,
    private screenWidth: number,
    private screenHeight: number,
    private getPlanogramCoordinates: (x: number, y: number) => Vector2,
    cookie?: string
  ) {
    super();
    this.updatePlanogram(planogramVersionId, cookie);
  }

  async get(url: string, headers?): Promise<Response> {
    if (!ENABLE_HEATMAP_SERVICE) return new Response();
    return super.get(url, headers);
  }

  updatePlanogram(planogramVersionId, cookie?) {
    this.planogramVersionId = planogramVersionId;
    if (cookie) {
      this.matomoUserId = cookie.match(this.MATOMO_USER_ID_REGEX);
      this.matomoSessionId = cookie.match(this.MATOMO_SESSION_ID_REGEX);
    }
  }

  updateScreenSize(screenWidth: number, screenHeight: number) {
    this.screenWidth = screenWidth;
    this.screenHeight = screenHeight;
  }

  generateBaseUrl(eventType: HEATMAP_EVENT_TYPES, coords: Vector2, zoomLevel: number) {
    const timestamp = new Date(Date.now()).toISOString();
    CookiesManagement.updateLastActionTime();
    return `${URLS.HEATMAP_URL}?\
planogram_version_id=${this.planogramVersionId}&event_type=${eventType}\
&timestamp=${timestamp}\
&x=${Math.round(coords.x)}&y=${Math.round(coords.y)}\
&screen_width=${this.screenWidth}\
&screen_height=${this.screenHeight}\
&zoom_level=${zoomLevel}\
${this.matomoUserId ? `&matomo_user_id=${this.matomoUserId[1]}` : ''}\
${this.matomoSessionId ? `&matomo_session_id=${this.matomoSessionId[1]}` : ''}`;
  }

  sendClickEvent(x: number, y: number, zoomLevel: number, item: SphereItem | undefined) {
    zoomLevel = Math.round(zoomLevel * 100) / 100.0;
    const coords = this.getPlanogramCoordinates(x, y);
    const baseUrl = this.generateBaseUrl(HEATMAP_EVENT_TYPES.CLICK, coords, zoomLevel);
    const itemUrl = item?.action !== undefined ? makeItemUrl(item) : '';
    const clickUrl = `${baseUrl}${itemUrl}`;
    this.get(clickUrl, {});
  }

  private previousZoomTime: number = Date.now();
  private previousZoomLevel: number = 0.0;
  private debounceZoomFunction = debounce(400, (coords, newZoomLevel) => {
    const zoomDuration = Math.round((Date.now() - this.previousZoomTime) / 1000.0);
    const baseUrl = this.generateBaseUrl(
      newZoomLevel > this.previousZoomLevel ? HEATMAP_EVENT_TYPES.ZOOM_IN : HEATMAP_EVENT_TYPES.ZOOM_OUT,
      coords,
      this.previousZoomLevel
    );
    this.previousZoomTime = Date.now();
    const zoomUrl = `${baseUrl}&duration=${zoomDuration}&final_zoom_level=${newZoomLevel}`;
    this.previousZoomLevel = newZoomLevel;
    this.get(zoomUrl, {});
  }).bind(this);
  sendZoomEvent(x: number, y: number, zoomLevel: number, newZoomLevel: number) {
    zoomLevel = Math.round(zoomLevel * 100) / 100.0;
    if (newZoomLevel === this.previousZoomLevel) {
      return;
    }
    const coords = this.getPlanogramCoordinates(x, y);
    this.debounceZoomFunction(coords, newZoomLevel);
  }

  private previousMovePosition: Vector2 = undefined;
  private previousMoveTime: number;
  sendMoveEvent(x: number, y: number, zoomLevel: number, direction: string) {
    if (this.previousMovePosition === undefined) {
      this.previousMovePosition = this.getPlanogramCoordinates(x, y);
      this.previousMoveTime = Date.now();
      return;
    }
    zoomLevel = Math.round(zoomLevel * 100) / 100.0;
    const baseUrl = this.generateBaseUrl(HEATMAP_EVENT_TYPES.MOVE, this.previousMovePosition, zoomLevel);

    const duration = Math.round((Date.now() - this.previousMoveTime) / 1000.0);
    this.previousMoveTime = Date.now();

    const coords = this.getPlanogramCoordinates(x, y);
    const moveUrl = `${baseUrl}&duration=${duration}&direction=${direction}&destination_x=${Math.round(
      coords[0]
    )}&destination_y=${Math.round(coords[1])}`;
    this.previousMovePosition = this.getPlanogramCoordinates(x, y);
    this.get(moveUrl, {});
  }
}
