import type { Material, WebGLRenderer } from 'three';

import type PlanogramPoint from 'shared/utils/PlanogramPoint';
import {
  debugFloatPrameter,
  debugIntParameter,
  debugParameter,
  hasDebugFlag,
} from 'shared/utils/debug';

import ImageRegistry from './ImageRegistry';
import { TileMap } from './TileMap';
import LodMaterial from './LodMaterial';
import PhysicalTextures from './PhysicalTextures';
import TileLoader from './TileLoader';
import { TextureCache } from './TextureCache';
import { TilePriority } from './TilePriority';
import LodDebug from './LodDebug';
import type { ExtraImageData, ImageId, ImageLodData } from './interfaces';

interface LodParameters {
  tileMapResolution: number;
  physicalTextureCount: number;
  physicalTextureResolution: number;
  simultaneousTilesLoading: number;
  targetPixelRatio: number;
  downgradeOversharps: boolean;
  unloadedTileBias: number;
  cdnUrl: string;
}

const defaultParameters: LodParameters = {
  tileMapResolution: 4096,
  physicalTextureCount: 8,
  physicalTextureResolution: 4096,
  simultaneousTilesLoading: 8,
  targetPixelRatio: 1,
  downgradeOversharps: true,
  unloadedTileBias: 0.5,
  cdnUrl: '',
};

function fallbackLodParameters(input: Partial<LodParameters>, gl: WebGLRenderer): LodParameters {
  const defaultedParameters = {
    ...defaultParameters,
    ...input,
  };
  const parameters = {
    ...defaultedParameters,
    tileMapResolution: debugIntParameter(
      'TILEMAP_RESOLUTION',
      defaultedParameters.tileMapResolution,
    ),
    physicalTextureCount: debugIntParameter(
      'PHYSICAL_TEXTURE_COUNT',
      defaultedParameters.physicalTextureCount,
    ),
    physicalTextureResolution: debugIntParameter(
      'PHYSICAL_TEXTURE_RESOLUTION',
      defaultedParameters.physicalTextureResolution,
    ),
    simultaneousTilesLoading: debugIntParameter(
      'SIMULTANEOUS_TILES_LOADING',
      defaultedParameters.simultaneousTilesLoading,
    ),
    targetPixelRatio: debugFloatPrameter(
      'TARGET_PIXEL_RATIO',
      defaultedParameters.targetPixelRatio,
    ),
    downgradeOversharps: debugParameter(
      'downgradeOversharps',
      defaultedParameters.downgradeOversharps,
      it => it === 'true',
    ),
    unloadedTileBias: debugFloatPrameter(
      'UNLOADED_TILE_BIAS',
      defaultedParameters.unloadedTileBias,
    ),
  };
  parameters.physicalTextureResolution = Math.min(
    gl.capabilities.maxTextureSize,
    parameters.physicalTextureResolution,
  );
  parameters.tileMapResolution = Math.min(
    gl.capabilities.maxTextureSize,
    parameters.tileMapResolution,
  );
  parameters.physicalTextureCount = Math.min(
    gl.capabilities.maxTextures - 1,
    parameters.physicalTextureCount,
  );
  return parameters;
}

export default class LodProvider {
  private imageRegistry: ImageRegistry;
  private tileMap: TileMap;
  private physicalTextures: PhysicalTextures;
  private tileLoader: TileLoader;
  private textureCache: TextureCache;
  private tilePriority: TilePriority;
  private materials: Map<ImageId, LodMaterial> = new Map();
  private parameters: LodParameters;

  public readonly debug?: LodDebug;

  constructor(renderer: WebGLRenderer, parameterOverrides: Partial<LodParameters>) {
    const parameters = fallbackLodParameters(parameterOverrides, renderer);
    this.parameters = parameters;

    this.tileMap = new TileMap(parameters.tileMapResolution, renderer);
    this.physicalTextures = new PhysicalTextures(
      parameters.physicalTextureCount,
      parameters.physicalTextureResolution,
      renderer,
    );
    this.textureCache = new TextureCache();
    this.tilePriority = new TilePriority(parameters.targetPixelRatio, parameters.unloadedTileBias);
    this.tileLoader = new TileLoader(
      this.tileMap,
      this.textureCache,
      this.physicalTextures,
      this.tilePriority,
      parameters.cdnUrl,
      parameters.simultaneousTilesLoading,
      parameters.downgradeOversharps,
      parameters.unloadedTileBias,
    );
    this.imageRegistry = new ImageRegistry(this.tileMap, this.tileLoader);

    this.imageRegistry.onImageReady((id: ImageId) => {
      const material = this.materials.get(id) ?? this.makeMaterial();
      this.materials.set(id, material);
      material.setItemUniforms(this.imageRegistry.getImageUniforms(id)!);
    });

    if (hasDebugFlag('physicalTextures')) this.debug = new LodDebug(this.physicalTextures.textures);
  }

  update() {
    const noWork = [
      this.tileMap.update(),
      this.physicalTextures.update(),
      this.tileLoader.update(),
    ].every(it => it);
    if (noWork && !this.imageRegistry.isLoading()) {
      this.loadedListeners.forEach(it => it());
    }
    this.debug?.update();
  }

  updateImageList(
    images: Map<ImageId, ExtraImageData>,
    lodLoader: (ids: number[]) => Promise<ImageLodData[]>,
  ) {
    this.imageRegistry.updateImages(images, lodLoader);
  }

  updateCamera(position: PlanogramPoint, pixelsToPlanogramRatio: number) {
    this.tilePriority.setFocusPoint(position);
    this.tilePriority.setPixelRatio(pixelsToPlanogramRatio);
  }

  private makeMaterial() {
    return new LodMaterial(
      this.tileMap,
      this.physicalTextures.textures,
      this.parameters.physicalTextureResolution,
      hasDebugFlag('lodColors'),
    );
  }

  getMaterial(id: ImageId): Material {
    let material = this.materials.get(id);
    if (material === undefined) {
      material = this.makeMaterial();
      this.materials.set(id, material);
    }
    return material;
  }

  private loadedListeners = new Set<() => void>();

  addLoadedListener(listener: () => void) {
    this.loadedListeners.add(listener);
  }

  removeLoadedListener(listener: () => void) {
    this.loadedListeners.delete(listener);
  }

  dispose() {
    this.debug?.dispose();
    this.tileLoader.dispose();
    this.physicalTextures.dispose();
    this.textureCache.dispose();
    this.tileMap.dispose();
  }
}
