export class ContentOverlay {
  container: HTMLElement;

  constructor(container: HTMLElement, private url: string, private closeCallback: Function) {
    this.container = container;
    container.innerHTML = '';
    const root = document.createElement('div');
    root.classList.add('iframe-overlay', 'overlay-container', 'is-flex', 'is-vertical', 'is-vertically-aligned-center', 'is-horizontally-aligned-center');
    this.container.appendChild(root);

    const closeButton = document.createElement('div');
    closeButton.classList.add('overlay-button', 'overlay-button-close', 'will-close');
    closeButton.appendChild(document.createElement('span'));
    closeButton.addEventListener('click', this.closeOverlay.bind(this));
    root.appendChild(closeButton);

    const holder = document.createElement('div');
    holder.classList.add('iframe-holder');
    holder.style.setProperty('background-color', 'transparent');
    root.appendChild(holder);

    this.fetchType(url).then(type => {
      const isImage = type.match(/^image\/.*$/) !== null;
      const content = document.createElement(isImage ? 'img' : 'iframe');
      isImage ?
        holder.style.setProperty('background-color', 'transparent') :
        holder.style.removeProperty('background-color');
      content.src = this.url;
      content.style.objectFit = 'contain';
      content.style.width = '100%';
      content.style.height = '100%';
      holder.appendChild(content);
    });
  }

  private fetchType(url: string) {
    return fetch(url, { method: 'HEAD' }).then(r => r.headers.get('Content-Type'));
  }

  private closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    return e.target.classList.contains('iframe-overlay');
  }
}
