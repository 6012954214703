import {PRODUCT_VIDEO_PROVIDER, PRODUCT_VIDEO_THUMB_TYPE} from '../shared/constants';
import {URLS} from '../api/urls';

const YOUTUBE_URL_REGEX = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g;
const VIMEO_URL_REGEX = /^((?:https?:)?\/\/)?((?:www|m|player)\.)?((?:vimeo\.com))(?:$|\/|)(\S+)?$/;

enum YOUTUBE_THUMB_VERSION {
  DEFAULT = 'default',
  HQ = 'hqdefault',
  MQ = 'mqdefault',
  SD = 'sddefault',
  MAX = 'maxresdefault'
}

enum VIMEO_THUMB_VERSION {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

export class VideoUtils {
  private static getYoutubeId(url: string): string {
    const [a, , b] = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (b !== undefined) {
      return b.split(/[^0-9a-z_-]/i)[0];
    } else {
      return a;
    }
  }

  private static getVimeoId(url: string): string {
    return url.match(VIMEO_URL_REGEX)[4];
  }

  private static getYoutubeVideoThumbnail(url: string, version = YOUTUBE_THUMB_VERSION.HQ): string {
    const id = VideoUtils.getYoutubeId(url);
    return `https://img.youtube.com/vi/${id}/${version}.jpg`;
  }

  private static getVimeoThumbnail(url: string, version = VIMEO_THUMB_VERSION.LARGE): string {
    const id = VideoUtils.getVimeoId(url);
    return `https://vumbnail.com/${id}_${version}.jpg`;
  }

  static thumbnailUrl(url: string, type: PRODUCT_VIDEO_THUMB_TYPE) {
    const provider = this.parseVideoProvider(url);
    switch (provider) {
      case PRODUCT_VIDEO_PROVIDER.YOUTUBE: {
        const version =
          type === PRODUCT_VIDEO_THUMB_TYPE.VIDEO_PREV ? YOUTUBE_THUMB_VERSION.HQ : YOUTUBE_THUMB_VERSION.DEFAULT;
        return VideoUtils.getYoutubeVideoThumbnail(url, version);
      }
      case PRODUCT_VIDEO_PROVIDER.VIMEO: {
        const version =
          type === PRODUCT_VIDEO_THUMB_TYPE.VIDEO_PREV ? VIMEO_THUMB_VERSION.LARGE : VIMEO_THUMB_VERSION.MEDIUM;
        return VideoUtils.getVimeoThumbnail(url, version);
      }
      default: {
        return URLS.OVERLAY_GALLERY_VIDEO_IMAGE_FALLBACK;
      }
    }
  }

  private static parseVideoProvider(url: string): PRODUCT_VIDEO_PROVIDER {
    if (url.match(YOUTUBE_URL_REGEX)) return PRODUCT_VIDEO_PROVIDER.YOUTUBE;
    else if (url.match(VIMEO_URL_REGEX)) return PRODUCT_VIDEO_PROVIDER.VIMEO;
    else return PRODUCT_VIDEO_PROVIDER.DIRECT_LINK;
  }

  static sanitizeUrl(url: string): string | undefined {
    const provider = this.parseVideoProvider(url);
    switch (provider) {
      case PRODUCT_VIDEO_PROVIDER.YOUTUBE:
        return `https://www.youtube.com/embed/${VideoUtils.getYoutubeId(url)}`;
      case PRODUCT_VIDEO_PROVIDER.VIMEO:
        return `https://player.vimeo.com/${VideoUtils.getVimeoId(url)}`;
      case PRODUCT_VIDEO_PROVIDER.DIRECT_LINK:
        return url;
      default:
        console.error('The existing link is not correct');
        return undefined;
    }
  }
}
