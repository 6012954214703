import {Box2, PerspectiveCamera, Ray, Raycaster, Vector2, Vector3} from 'three';
import {ArrayUtils} from '../utils/array_utils';
import {debounce} from 'throttle-debounce';
import {Animation} from '../animations/animation';
import {AutoRotateAnimation} from '../animations/auto_rotate_animation';
import {MomentumAnimation} from '../animations/momentum_animation';
import {ZoomToAnimation} from '../animations/zoom_to_animation';
import {RotationUtils} from '../utils/rotation_utils';
import {SphereApp} from '../sphere_app';
import {BASE_FOV, Camera} from '../camera';
import {CookiesManagement} from '../cookies_management';
import {SPHERE_EVENT_NAMES as EVENTS} from '../event-names';
import {sphereEventHandler} from '../custom_event_utils';
import {AnimationOptions} from '../interfaces/planogram.interface';
import {Metrics} from '../metrics';
import {MATOMO_EVENT_NAMES} from '../metric-events';
import {SphereShape} from 'shared/utils/SphereShape';
import {invertYAxis, normalizeMouse} from '../utils/math_utils';
import {WebUtils} from '../utils/web_utils';
import {SPHERE_BG_EVENT_NAME} from '../shared/constants';
import {SphereItems} from '../sphere_items';
import {Modulo} from '../utils/moduloUtils';
import {Planogram} from '../planogram';
import {SphereItem} from '../sphere_item';

const ENTRANCE_ANIMATION_ZOOM_IN_FOV = 21.34;

const ENTRANCE_ANIMATION_DRAG_VALUE = 400;

export class CameraControls {
  private animationChain: Set<Animation>;
  private angularSpeedsX: Array<number>;
  private angularSpeedsY: Array<number>;
  private lastUpdatedAt: number;
  private oldZoom: number;
  private analyticZoomEventFunc: (
    zoomDirection: string,
    currentZoom: number,
    coords: Vector2,
    mouseOnPlanogram: Vector2
  ) => void;
  private modulo: Modulo = new Modulo(Planogram.pages());

  private pointerCameraState: PerspectiveCamera;
  private pointerIntersect: Vector3;

  private cameraDistance: number;
  private sphereApp: SphereApp;
  private clusterMidPoint: Vector3;
  private storedClusterNormal: Vector3;

  static get ROTATE_LEFT() {
    return 1;
  }

  static get ROTATE_RIGHT() {
    return -1;
  }

  static get TILT_UP() {
    return 1;
  }

  static get TILT_DOWN() {
    return -1;
  }

  static get MAX_SPEED_ELEMENT_COUNT() {
    return 5;
  }

  static limitSpeed(speed) {
    const MAXMUM_MOMENTUM_START_SPEED = 0.05;

    if (Math.abs(speed) > MAXMUM_MOMENTUM_START_SPEED) {
      return undefined;
    }

    return speed;
  }

  constructor(
    private camera: Camera,
    private sphereShape: SphereShape,
    private sphereItems: SphereItems,
    sphereApp: SphereApp
  ) {
    this.sphereApp = sphereApp;
    this.clusterMidPoint = new Vector3();
    this.storedClusterNormal = new Vector3();
    this.animationChain = new Set();
    this.angularSpeedsX = [];
    this.angularSpeedsY = [];
    this.analyticZoomEventFunc = debounce(400, this.analyticZoomEvent.bind(this));

    sphereEventHandler.listen(EVENTS.ENTRANCE_ANIMATION.ZOOM_ANIMATION, this.entranceAnimationZoom.bind(this));
    sphereEventHandler.listen(EVENTS.ENTRANCE_ANIMATION.DRAG_ANIMATION, this.entranceAnimationDrag.bind(this));
  }

  private findFarthestMouseIntersect(screenPosition: {x: number; y: number}, camera?: PerspectiveCamera) {
    const mousePoint = normalizeMouse(screenPosition.x, screenPosition.y);
    const caster = new Raycaster();
    caster.setFromCamera(mousePoint, camera ?? this.camera.perspectiveCamera);
    return this.sphereShape.castRayFarthestPoint(caster.ray, new Vector3());
  }

  private analyticZoomEvent(zoomDirection: string, currentZoom: number, coords: Vector2, mouseOnPlanogram: Vector2) {
    const nearestItem = this.nearestItem(mouseOnPlanogram);
    Metrics.storeTheEvent(
      this.sphereApp.planogram.name,
      'zoom',
      MATOMO_EVENT_NAMES.CAMERA_ZOOM(
        zoomDirection,
        currentZoom,
        Math.round(coords.x),
        Math.round(coords.y),
        nearestItem?.itemData?.name ?? SPHERE_BG_EVENT_NAME
      )
    );
  }

  private nearestItem(mouseOnPlanogram: Vector2) {
    const viewport = this.camera.getViewport();
    const itemBox = new Box2();
    const validItems = this.sphereItems.items.filter(it =>
      viewport.containsBox(itemBox.setFromCenterAndSize(it.getViewportCenter(), it.getSize()))
    );
    if (validItems.length === 0) return undefined;
    else
      return validItems.reduce((nearest, it) => {
        if (
          this.modulo.distanceV(mouseOnPlanogram, it.getViewportCenter()) <
          this.modulo.distanceV(mouseOnPlanogram, nearest.getViewportCenter())
        )
          return it;
        else return nearest;
      });
  }

  currentZoomFraction() {
    return this.camera.currentZoomFraction();
  }

  autoRotate(direction) {
    const autoRotate = AutoRotateAnimation.instance;
    autoRotate.addRotation(direction, this.currentZoomFraction());
    if (autoRotate.isNotRotating()) {
      this.stopAutoRotate();
    } else {
      this.addAnimation(autoRotate);
    }
    sphereEventHandler.emit(EVENTS.AUTOROTATE, {direction}); // R & L Keys
  }

  stopAutoRotate() {
    const autoRotate = AutoRotateAnimation.instance;
    autoRotate.clearRotation();
    this.removeAnimation(autoRotate);
  }

  zoomBy(zoomFactor: number) {
    this.camera.zoomBy(zoomFactor);
  }

  animateZoomFov() {
    if (!this.sphereApp.planogram.clustersOrder) {
      return;
    }
    const current = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
    const clusterHorMid = new Vector3(this.clusterMidPoint.x, 1, this.clusterMidPoint.z);
    const fullItemFOV = this.camera.clampFOV(BASE_FOV);
    const cluster = this.sphereShape.castRayFarthestPoint(
      new Ray(clusterHorMid, this.storedClusterNormal),
      new Vector3()
    );
    const animation = new ZoomToAnimation(
      this.camera.fov(),
      current,
      fullItemFOV,
      cluster,
      undefined,
      1,
      0,
      this.sphereApp.planogram.animationSettings.duration,
      this.sphereApp.planogram.animationSettings.transition_type,
      this.sphereApp.planogram.animationSettings.pan_before_zoom
    );
    this.addAnimation(animation);
    sphereEventHandler.emit(EVENTS.ANIMATE_ZOOM_FOV);
    return animation;
  }

  entranceAnimationZoom() {
    const makeZoomAnimation = (fov: number, endCallback?: () => void) => {
      const point = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());

      return new ZoomToAnimation(
        this.camera.fov(),
        point,
        fov,
        point,
        endCallback,
        1,
        0,
        this.sphereApp.planogram.animationSettings.duration,
        this.sphereApp.planogram.animationSettings.transition_type,
        this.sphereApp.planogram.animationSettings.pan_before_zoom
      );
    };

    const endCallbackFunc = () => this.addAnimation(makeZoomAnimation(BASE_FOV));
    const fullItemFOV = this.camera.clampFOV(ENTRANCE_ANIMATION_ZOOM_IN_FOV);
    this.addAnimation(makeZoomAnimation(fullItemFOV, endCallbackFunc));
  }

  entranceAnimationDrag() {
    const currentPoint = new Vector3();
    this.sphereShape.castRayFarthestPoint(this.camera.ray, currentPoint);
    const finalDestination = currentPoint.clone();
    finalDestination.x -= ENTRANCE_ANIMATION_DRAG_VALUE;

    const makeDragAnimation = (duration: number, endCallback?: () => void) => {
      this.sphereShape.castRayFarthestPoint(this.camera.ray, currentPoint);
      const fullItemFOV = this.camera.clampFOV(this.camera.fov());
      return new ZoomToAnimation(
        this.camera.fov(),
        currentPoint,
        fullItemFOV,
        finalDestination,
        endCallback,
        1,
        0,
        this.sphereApp.planogram.animationSettings.duration * duration,
        this.sphereApp.planogram.animationSettings.transition_type,
        this.sphereApp.planogram.animationSettings.pan_before_zoom
      );
    };

    const dragToInitialPositionCallback = () => {
      finalDestination.x -= ENTRANCE_ANIMATION_DRAG_VALUE;
      const dragToInitialPosition = makeDragAnimation(0.5);
      this.addAnimation(dragToInitialPosition);
    };
    const dragLeftAnimationCallback = () => {
      finalDestination.x += 2 * ENTRANCE_ANIMATION_DRAG_VALUE;
      const dragLeftAnimation = makeDragAnimation(1.0, dragToInitialPositionCallback);
      this.addAnimation(dragLeftAnimation);
    };
    const dragRightAnimation = makeDragAnimation(0.5, dragLeftAnimationCallback);
    this.addAnimation(dragRightAnimation);
  }

  zoomToPoint(newPoint: {x: number; y: number}, zoomScaleFactor: number) {
    this.moveCameraToFlattenView();
    let previousIntersect = this.pointerIntersect;
    if (!previousIntersect) {
      previousIntersect = this.findFarthestMouseIntersect(newPoint);
    }

    const oldZoomLevel = this.camera.currentZoomFraction();

    this.camera.zoomBy(zoomScaleFactor);
    this.camera.updateCamera();

    if (this.camera.currentZoomFraction() !== oldZoomLevel) {
      const mouseIntersect = this.findFarthestMouseIntersect(newPoint);
      const mouseOnPlanogram = this.sphereShape.reverse(mouseIntersect);
      const coords = this.sphereApp.getPlanogramCoordinates(newPoint.x, newPoint.y);
      const zoomDirection = this.camera.currentZoomFraction() > oldZoomLevel ? 'zoom_in' : 'zoom_out';
      const currentZoom = Math.round(this.camera.currentZoomFraction() * 100);

      if (coords?.length && currentZoom !== this.oldZoom) {
        this.analyticZoomEventFunc(zoomDirection, currentZoom, coords, mouseOnPlanogram);
        this.oldZoom = currentZoom;
      }
    }

    this.sphereApp.heatMapService.sendZoomEvent(
      newPoint.x,
      newPoint.y,
      oldZoomLevel,
      this.camera.currentZoomFraction()
    );

    const newIntersect = this.findFarthestMouseIntersect(newPoint);
    this.tiltAndPanBetween(newIntersect, previousIntersect);
    sphereEventHandler.emit(EVENTS.CAMERA.ZOOM_TO_POINT, {
      newIntersect,
      previousIntersect
    }); // zoom (mouse wheel, pinch)
  }

  onMovementStart(pointer) {
    this.lastUpdatedAt = Date.now();
    this.pointerIntersect = this.findFarthestMouseIntersect(pointer);
    this.pointerCameraState = this.camera.perspectiveCamera.clone();
    sphereEventHandler.emit(EVENTS.ON_MOVEMENT_START, {pointer}); // mouse click
  }

  private tiltAndPanToSpherePoint(spherePoint) {
    const midIntersect = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
    this.tiltAndPanBetween(midIntersect, spherePoint.point);
  }

  tiltAndPanTo(pointer: {x: number; y: number}) {
    const newIntersect = this.findFarthestMouseIntersect(pointer, this.pointerCameraState);
    this.tiltAndPanBetween(newIntersect, this.pointerIntersect);
    this.pointerIntersect = newIntersect;
    this.lastUpdatedAt = Date.now();
    sphereEventHandler.emit(EVENTS.TILT_AND_PAN_TO, {pointer}); // mouse pan
  }

  private tiltAndPanBy(adjustment: {pan?: number; tilt?: number}) {
    this.camera.tiltAndPanBy(adjustment);
    this.moveCameraToFlattenView();
    this.camera.updateCamera();
  }

  onMovementEnd() {
    if (this.isDragging()) {
      const avgAngularSpeedsX = ArrayUtils.average(this.angularSpeedsX);
      const startPoint = this.sphereShape.planogramCoordinateViewer(
        this.sphereShape.reverse(this.pointerIntersect),
        this.sphereApp.planogram.size()
      );
      const invertedStartPoint = invertYAxis(startPoint, this.sphereApp.planogram.size().y);
      const finalPoint = this.sphereApp.getPlanogramCoordinates(window.innerWidth / 2, window.innerHeight / 2);
      const dragDirection = avgAngularSpeedsX < 0 ? 'right' : 'left';
      const midIntersect = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
      const nearestItem = this.nearestItem(this.sphereShape.reverse(midIntersect));

      Metrics.storeTheEvent(
        this.sphereApp.planogram.name,
        'drag',
        MATOMO_EVENT_NAMES.DRAG_EVENT(
          dragDirection,
          Math.round(invertedStartPoint.x),
          Math.round(invertedStartPoint.y),
          Math.round(finalPoint.x),
          Math.round(finalPoint.y),
          nearestItem?.itemData?.name ?? SPHERE_BG_EVENT_NAME
        )
      );
      this.sphereApp.heatMapService.sendMoveEvent(
        window.innerWidth / 2,
        window.innerHeight / 2,
        this.camera.currentZoomFraction(),
        dragDirection
      );

      this.addAnimation(new MomentumAnimation(avgAngularSpeedsX, this.lastUpdatedAt, MomentumAnimation.X_AXIS));
      this.addAnimation(
        new MomentumAnimation(ArrayUtils.average(this.angularSpeedsY), this.lastUpdatedAt, MomentumAnimation.Y_AXIS)
      );
    }
    this.angularSpeedsX = [];
    this.angularSpeedsY = [];
    this.lastUpdatedAt = undefined;
    this.pointerIntersect = undefined;
  }

  addAnimation(animation) {
    this.animationChain.add(animation);
  }

  removeAnimation(animation) {
    this.animationChain.delete(animation);
  }

  updateAnimations() {
    Array.from(this.animationChain.values()).forEach(animation => {
      const adjustment = animation.getAdjustment();
      if (!adjustment) {
        this.removeAnimation(animation);
      } else if (adjustment.fov) {
        this.camera.update(adjustment);
        this.tiltAndPanToSpherePoint(adjustment.targetPoint);
      } else {
        this.camera.update(adjustment);
      }
    });
  }

  clearAnimation(isSphereRotate?) {
    if (CookiesManagement.isRedirectAnimationProcessing && isSphereRotate) {
      setTimeout(() => CookiesManagement.init(), 0);
      CookiesManagement.isRedirectAnimationProcessing = false;
    }

    AutoRotateAnimation.clearInstance();
    this.animationChain.clear();
  }

  static getItemSize(arr: number[]) {
    return arr.indexOf(Math.max(...arr));
  }

  getCameraFov(value: number) {
    return 2 * Math.atan(value / (2 * this.cameraDistance)) * (180 / Math.PI);
  }

  static findProperFov(fovWidth, fovHeight) {
    return Math.max(fovWidth, fovHeight);
  }

  createZoomToAnimation(item: SphereItem, endCallback: Function, options: AnimationOptions = {}) {
    const {delay, duration, transitionType, panBeforeZoom, clusterAnimation = false} = options;
    const cameraRadius = this.camera.initialZPosition();
    this.cameraDistance = cameraRadius + item.planogram.fixedRadius;
    const [width, height] = item.getSize().toArray();
    const i = CameraControls.getItemSize([width, height]);
    const itemConfiguration = i
      ? {
          size: height,
          zoomStopPoint: 0.2
        }
      : {
          size: width / WebUtils.aspectRatio(),
          zoomStopPoint: 0.25
        };
    const fovWidth = this.getCameraFov(width / WebUtils.aspectRatio());
    const fovHeight = this.getCameraFov(height);
    const fullItemFOV = this.camera.clampFOV(
      clusterAnimation ? CameraControls.findProperFov(fovWidth, fovHeight) : this.getCameraFov(itemConfiguration.size)
    );
    const intersect = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
    const [sceneCenter, sceneNormal] = item.getCenter(this.sphereShape);
    this.clusterMidPoint.copy(sceneCenter);
    this.storedClusterNormal.copy(sceneNormal);
    return new ZoomToAnimation(
      this.camera.fov(),
      intersect,
      fullItemFOV,
      sceneCenter,
      endCallback,
      clusterAnimation ? 1 : itemConfiguration.zoomStopPoint,
      delay,
      duration ?? this.sphereApp.planogram.animationSettings.duration,
      transitionType ?? this.sphereApp.planogram.animationSettings.transition_type,
      panBeforeZoom !== undefined ? panBeforeZoom : this.sphereApp.planogram.animationSettings.pan_before_zoom
    );
  }

  animateTo(item: SphereItem, endCallback: Function, options?: AnimationOptions) {
    const zoomToAnimation = this.createZoomToAnimation(item, endCallback, options);
    this.addAnimation(zoomToAnimation);
    return zoomToAnimation;
  }

  private tiltAndPanBetween(latestIntersect: Vector3 | undefined, previousIntersect: Vector3 | undefined) {
    const tiltAngle = RotationUtils.tiltAngleBetween(latestIntersect, previousIntersect, this.camera.position);
    const panAngle = RotationUtils.panAngleBetween(latestIntersect, previousIntersect);
    if (this.lastUpdatedAt) {
      const timePeriod = Date.now() - this.lastUpdatedAt;
      this.angularSpeedsX = ArrayUtils.append(
        this.angularSpeedsX,
        CameraControls.limitSpeed(panAngle / timePeriod),
        CameraControls.MAX_SPEED_ELEMENT_COUNT
      );
      this.angularSpeedsY = ArrayUtils.append(
        this.angularSpeedsY,
        CameraControls.limitSpeed(tiltAngle / timePeriod),
        CameraControls.MAX_SPEED_ELEMENT_COUNT
      );
    }
    this.tiltAndPanBy({tilt: tiltAngle, pan: panAngle});
  }

  private moveCameraToFlattenView() {
    const point = this.sphereShape.castRayFarthestPoint(this.camera.ray, new Vector3());
    const normal = this.sphereShape.normalAt(this.sphereShape.reverse(point));
    this.camera.rotateToNormal({point, normal});
  }

  private isDragging() {
    return this.angularSpeedsX.length > 0 && this.angularSpeedsY.length > 0 && Date.now() - this.lastUpdatedAt < 50;
  }
}
