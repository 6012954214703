import GUI from 'lil-gui';
import {PAGE_LOADING_TYPES} from './shared/constants';

const PAGE_LOADING_SPEED = {
  LOADING: type => `${type}Loading`,
  LOADED: type => `${type}Loaded`,
  LOADING_TIME: type => `${type}LoadingTime`
};

let folder;
let datGui;

export class PageLoadingSpeed {
  static stats: object = {};

  constructor(private gui: GUI) {
    this.initLogs();
  }

  private static clearMeasure(type: PAGE_LOADING_TYPES) {
    window.performance.clearMarks(PAGE_LOADING_SPEED.LOADING(type));
    window.performance.clearMarks(PAGE_LOADING_SPEED.LOADED(type));
    window.performance.clearMeasures(PAGE_LOADING_SPEED.LOADING_TIME(type));
  }

  static startMeasure(type: PAGE_LOADING_TYPES) {
    window.performance.mark(PAGE_LOADING_SPEED.LOADING(type));
  }

  static completeMeasure(type: PAGE_LOADING_TYPES) {
    window.performance.mark(PAGE_LOADING_SPEED.LOADED(type));
    window.performance.measure(
      PAGE_LOADING_SPEED.LOADING_TIME(type),
      PAGE_LOADING_SPEED.LOADING(type),
      PAGE_LOADING_SPEED.LOADED(type)
    );
    const {duration} = window.performance.getEntriesByName(PAGE_LOADING_SPEED.LOADING_TIME(type))[0];
    if (!PageLoadingSpeed.stats[type]) {
      PageLoadingSpeed.stats[type] = (duration / 1000).toFixed(2) + 's';
      folder?.add(PageLoadingSpeed.stats, type, PageLoadingSpeed.stats[type]);
    } else {
      PageLoadingSpeed.stats[type] = (duration / 1000).toFixed(2) + 's';
      datGui?.updateDisplay();
    }

    PageLoadingSpeed.clearMeasure(type);
  }

  private initLogs() {
    if (!this.gui) {
      return;
    }
    datGui = this.gui;
    folder = this.gui.addFolder('Loading Time');
    folder.open();
    folder.domElement.style.pointerEvents = 'none';
    Object.entries(PageLoadingSpeed.stats).map(([key, value]) => {
      folder.add(PageLoadingSpeed.stats, key, value);
    });
  }
}
