import PlanogramPoint from 'shared/utils/PlanogramPoint';
import PlanogramBox from 'shared/utils/PlanogramBox';
import { assertTrue, debugCommand } from 'shared/utils/debug';

import { TILE_CONTENT_SIZE } from './parameters';
import { HEIGHT, WIDTH } from '../config/PlanogramConfig';

const MIN_VALUE = Number.MIN_VALUE;
const MAX_DISTANCE = Math.sqrt(WIDTH * WIDTH + HEIGHT * HEIGHT) * 0.5;

function tileSize(ratio: number) {
  const size = TILE_CONTENT_SIZE / ratio;
  return {
    x: size,
    y: size,
  };
}

export interface ChunkPriorityData {
  point: PlanogramPoint;
  minPixelPlanogramRatio: number;
  maxPixelPlanogramRatio: number;
  canUpgrade: boolean;
  canDowngrade: boolean;
  someNotLoaded: boolean;
  someLoaded: boolean;
}

export class TilePriority {
  private focusPoint: PlanogramPoint = new PlanogramPoint();
  private pixelsToSizeRatio: number = 1;

  private minimumQuality: number;
  private maximumQuality: number;

  constructor(targetPixelRatio: number, private unloadedTileBias: number) {
    this.minimumQuality = targetPixelRatio * Math.SQRT1_2;
    this.maximumQuality = 2 * this.minimumQuality;
    debugCommand('tilePriority', () => this);
  }

  getFocusPoint() {
    return this.focusPoint;
  }

  setFocusPoint(point: PlanogramPoint) {
    this.focusPoint.copy(point);
  }

  setPixelRatio(ratio: number) {
    this.pixelsToSizeRatio = ratio;
  }

  rateLocation(location: PlanogramPoint, offset: number = 0): number {
    const distance = Math.max(MIN_VALUE, this.focusPoint.distance(location) + offset);
    const rating = (distance / MAX_DISTANCE) * distance; // divide to avoid huge numbers
    assertTrue(!isNaN(rating), 'NaN rating');
    return rating;
  }

  private ratePlanogramPixelRatio(ratio: number) {
    const qualityRatio = ratio / this.pixelsToSizeRatio;
    return Math.max(MIN_VALUE, qualityRatio);
  }

  needsUpgrade(chunk: ChunkPriorityData) {
    return (
      chunk.someNotLoaded ||
      this.ratePlanogramPixelRatio(chunk.minPixelPlanogramRatio) < this.minimumQuality
    );
  }

  needsDowngrade(chunk: ChunkPriorityData) {
    return this.ratePlanogramPixelRatio(chunk.maxPixelPlanogramRatio) > this.maximumQuality;
  }

  rateLocationAndRatio(location: PlanogramPoint, ratio: number) {
    const locationRating = this.rateLocation(location);
    return locationRating * this.ratePlanogramPixelRatio(ratio);
  }

  rateUpgrade(chunk: ChunkPriorityData, box: PlanogramBox) {
    const rating = this.rateBestInBox(box, chunk.minPixelPlanogramRatio);
    return rating + (chunk.canUpgrade && this.needsUpgrade(chunk) ? 0 : +Infinity);
  }

  rateDowngrade(chunk: ChunkPriorityData, box: PlanogramBox) {
    const rating = this.rateWorstInBox(box, chunk.maxPixelPlanogramRatio);
    return (
      -rating * (chunk.canDowngrade ? 1 : 2 ** -this.unloadedTileBias) +
      (chunk.someLoaded ? 0 : +Infinity)
    );
  }

  private upgradeRatio(notLoaded: boolean) {
    return notLoaded ? 2 ** (1 + this.unloadedTileBias) : 2;
  }

  estimateWorstUpgrade(chunk: ChunkPriorityData) {
    const ratio = chunk.minPixelPlanogramRatio * this.upgradeRatio(chunk.someNotLoaded);
    const box = new PlanogramBox().fromCenterAndSize(chunk.point, tileSize(ratio));
    return this.rateWorstInBox(box, ratio);
  }

  estimateBestDowngrade(chunk: ChunkPriorityData) {
    const ratio = chunk.maxPixelPlanogramRatio / this.upgradeRatio(!chunk.canDowngrade);
    const box = new PlanogramBox().fromCenterAndSize(chunk.point, tileSize(ratio));
    return this.rateBestInBox(box, ratio);
  }

  rateBestInBox(box: PlanogramBox, planogramPixelRatio: number): number {
    const bestPoint = box.clamp(this.focusPoint);
    return this.rateLocationAndRatio(bestPoint, planogramPixelRatio);
  }

  rateWorstInBox(box: PlanogramBox, planogramPixelRatio: number): number {
    const worstPoint = box.farthestFrom(this.focusPoint);
    return this.rateLocationAndRatio(worstPoint, planogramPixelRatio);
  }
}
