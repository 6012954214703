import type { PlanogramWidth } from 'shared/utils/PlanogramPoint';
import PlanogramPoint, { planogramWidth } from 'shared/utils/PlanogramPoint';
import CylinderQuadTree from 'shared/utils/CylinderQuadTree';
import PlanogramBox from 'shared/utils/PlanogramBox';
import { debugCommand } from 'shared/utils/debug';

import type { ChunkPriorityData, TilePriority } from './TilePriority';

interface TreeData extends ChunkPriorityData {
  // chunkId and point are only useful for specific chunks, not tree branches
  point: PlanogramPoint;
  chunkId: number;
}

export type ChunkSpatialData = TreeData;

function treeDataReducer(data: TreeData[]): TreeData {
  return {
    point: new PlanogramPoint(),
    minPixelPlanogramRatio: data.reduce(
      (min, it) => Math.min(min, it.minPixelPlanogramRatio),
      +Infinity,
    ),
    maxPixelPlanogramRatio: data.reduce((max, it) => Math.max(max, it.maxPixelPlanogramRatio), 0),
    canUpgrade: data.some(it => it.canUpgrade),
    canDowngrade: data.some(it => it.canDowngrade),
    someNotLoaded: data.some(it => it.someNotLoaded),
    someLoaded: data.some(it => it.someLoaded),
    chunkId: 0,
  };
}

function defaultTreeData(): TreeData {
  return {
    point: new PlanogramPoint(),
    minPixelPlanogramRatio: +Infinity,
    maxPixelPlanogramRatio: 0,
    canDowngrade: false,
    canUpgrade: false,
    someNotLoaded: false,
    someLoaded: false,
    chunkId: 0,
  };
}

export default class ChunkSpatialTree extends CylinderQuadTree<PlanogramWidth, TreeData> {
  constructor(private tilePriority: TilePriority) {
    super(
      planogramWidth,
      treeDataReducer,
      defaultTreeData,
      new PlanogramBox({
        min: { x: 0, y: -Infinity },
        max: { x: planogramWidth, y: +Infinity },
      }),
    );

    const self = this;
    debugCommand('lodSpatialTree', () => self);
  }

  findUpgrade() {
    const best = this.findLowestRating((data, box) => this.tilePriority.rateUpgrade(data, box));
    if (best === undefined || !best.canUpgrade || !this.tilePriority.needsUpgrade(best))
      return undefined;
    else return best;
  }

  findDowngrade() {
    const best = this.findLowestRating((data, box) => this.tilePriority.rateDowngrade(data, box));
    if (best === undefined || !best.someLoaded) return undefined;
    else return best;
  }
}
