import {InputEventUtils} from '../utils/input_event_utils';
import {VideoUtils} from '../utils/video_utils';

export class VideoOverlay {
  container: HTMLElement;

  constructor(container: HTMLElement, link: string, private closeCallback: Function) {
    const videoURL = VideoUtils.sanitizeUrl(link);

    if (!videoURL) {
      console.error('Generating process of youtube url was failed');
      setTimeout(() => {
        this.closeOverlay();
      }, 0);
      return;
    }

    this.container = container;
    this.container.classList.remove('direct-link');
    const videoContainer = document.createElement('div');
    const backdrop = document.createElement('div');
    const holder = document.createElement('div');
    const closeButton = document.createElement('div');
    const closeButtonElement = document.createElement('span');
    closeButton.classList.add('overlay-button', 'overlay-button-close', 'will-close');
    closeButton.appendChild(closeButtonElement);
    holder.classList.add('iframe-holder');
    videoContainer.classList.add(
      'video-overlay',
      'overlay-container',
      'is-flex',
      'is-vertical',
      'is-vertically-aligned-center',
      'is-horizontally-aligned-center'
    );
    backdrop.classList.add('close-backdrop');
    videoContainer.appendChild(holder);
    holder.appendChild(closeButton);
    this.container.appendChild(backdrop);
    this.container.appendChild(videoContainer);

    InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
    this.fetchType(link).then(type => {
      const isVideo = type && type.match(/^video\/.*$/) !== null;
      const content = document.createElement<'video' | 'iframe'>(isVideo ? 'video' : 'iframe');
      content.src = videoURL;

      if (isVideo) {
        this.container.classList.add('direct-link');
        content.setAttribute('controls', '');
        content.setAttribute('autoplay', '');
      } else {
        content.setAttribute('frameBorder', '0');
        content.setAttribute('allowfullscreen', '');
      }
      holder.appendChild(content);
    });
  }

  private fetchType(url: string) {
    return fetch(url, {method: 'HEAD'})
      .then(r => {
        return r.headers.get('Content-Type');
      })
      .catch(() => undefined);
  }

  private closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }

    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    return ['close-backdrop', 'video-overlay'].some(token => e.target.classList.contains(token));
  }
}
